import { faShareNodes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NextPage } from 'next';
import Image from 'next/image';
import { useForm } from 'react-hook-form';
import {
  AddressRequest,
  AddressRequestCountryEnum,
  CreateAgentLeadRequestAgentLeadTypeEnum,
} from '../../openapi/bff';
import { ListingDetailResponse } from '../../openapi/wanderer';
import BFFApiService from '../../services/BFFApiService';
import ErrorService from '../../services/ErrorService';
import { Agent, RealApiAgentWebsiteResponse } from '../../types/real-api-types';
import { isRealAgent } from '../../utils/AgentUtils';
import { getAgentWebsiteApiResponse } from '../../utils/agentWebsiteUtils';
import { displayAmount, MoneyValue } from '../../utils/CurrencyUtils';
import { stateAbbreviationToEnumMap } from '../../utils/DisplayHelpers';
import { getShareOptionItems, isRental } from '../../utils/PropertiesUtils';
import { capitalizeEnum, formatPhoneNumber } from '../../utils/StringUtils';
import Avatar from '../Avatar';
import Button from '../Button';
import ControlledTextAreaInputV7 from '../controlled-inputs/ControlledTextAreaInputV7';
import ControlledTextInputV7 from '../controlled-inputs/ControlledTextInputV7';
import GeminiContentOptions from './GeminiContentOptions';

interface GeminiContactAgentCardProps {
  price?: MoneyValue;
  estimatedMonthlyPrice?: MoneyValue;
  agent?: Agent;
  noShare?: boolean;
  onClose?(): void;
  property?: Partial<ListingDetailResponse>;
}

interface FormData {
  name: string;
  emailAddress: string;
  phoneNumber: string;
  message: string;
}

const GeminiContactAgentCard: NextPage<GeminiContactAgentCardProps> = ({
  price,
  estimatedMonthlyPrice,
  agent,
  property,
  noShare,
  onClose,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm<FormData>();
  const isAgentShown = agent && !isRealAgent(agent);

  const onSubmit = async (data: FormData): Promise<void> => {
    try {
      let addressRequest: AddressRequest | null = null;
      let agentID: number;
      if (property) {
        addressRequest = {
          type: '',
          city: property?.fullListing.address.city,
          country: AddressRequestCountryEnum.UnitedStates,
          streetAddress1: property?.fullListing.address.deliveryLine,
          streetAddress2: '',
          zipOrPostalCode: property?.fullListing.address.zip,
          stateOrProvince: property
            ? stateAbbreviationToEnumMap[property?.fullListing.address.state]
            : undefined,
        };
      }
      if (!isAgentShown) {
        const agentWebsiteResponse: RealApiAgentWebsiteResponse | undefined =
          await getAgentWebsiteApiResponse('real-agent');
        agentID = agentWebsiteResponse.agent.id;
      }

      await new BFFApiService().createAgentLead({
        agentId: isAgentShown
          ? (agent?.id as unknown as string)
          : (agentID as unknown as string),
        agentLeadType: CreateAgentLeadRequestAgentLeadTypeEnum.Property,
        email: data.emailAddress,
        listingId: property?.fullListing.id,
        message: data.message,
        mlsNumber: property?.fullListing.mlsId,
        name: data.name,
        phoneNumber: data.phoneNumber,
        addressRequest,
      });
    } catch (e) {
      ErrorService.notifyIgnoreStatusCodes(
        [403],
        'Unable to post leads to bff',
        e,
        {
          property: { ...property },
          data: { ...data },
        }
      );
    }
  };

  if (isSubmitSuccessful && onClose) {
    onClose();
  }

  return (
    <div className='w-full'>
      <div className='h-fit bg-transparent rounded-3xl w-full'>
        <form className='flex flex-col  p-3' onSubmit={handleSubmit(onSubmit)}>
          <div className='md:hidden w-full text-base font-primary-medium'>
            Listing Agent
          </div>

          {!noShare && (
            <div>
              <p className='text-light-blue-1 text-5xl font-telegraf'>
                {displayAmount(price, {
                  hideCurrency: true,
                  hideZeroCents: true,
                })}
              </p>

              {property && !isRental(property.fullListing) && (
                <p className='font-inter text-lg text-cobalt text-opacity-70 mt-1.5 text-left'>
                  Est.{' '}
                  {displayAmount(estimatedMonthlyPrice, {
                    hideCurrency: true,
                  })}
                  /mo
                </p>
              )}

              <div className='flex mt-5'>
                <GeminiContentOptions
                  label='Share'
                  icon={
                    <FontAwesomeIcon
                      icon={faShareNodes}
                      className='bg-blue-1 p-1'
                    />
                  }
                  containerClassName='z-50'
                  items={getShareOptionItems(undefined, property)}
                />
              </div>
            </div>
          )}

          {!noShare && <div className='w-full h-px bg-slate-300 hidden' />}
          {isAgentShown && (
            <div className='flex flex-row items-center p-2 md:px-5 my-2 md:mt-4 '>
              <Avatar
                name={agent.name}
                size='xl'
                imageUrl={agent.cloudinary_image_url}
              />
              <div className='grid grid-col gap-2 pl-4'>
                <div>
                  <p className='font-inter text-base'>{agent?.name}</p>
                  <p className='font-inter text-xs text-slate-400'>
                    {agent?.role ? capitalizeEnum(agent?.role) : 'Real Agent'}
                  </p>
                </div>
                <div>
                  <div className='flex flex-row items-center'>
                    <div className='w-4 h-4'>
                      <Image
                        src='/icons/email.svg'
                        width={14}
                        height={14}
                        alt='right'
                      />
                    </div>
                    <p className='font-primary-light pl-2 text-xs md:text-base break-all'>
                      {agent?.email}
                    </p>
                  </div>
                  {agent?.phone_1_display && (
                    <div className='flex flex-row items-center mt-1'>
                      <div className='w-4 h-4'>
                        <Image
                          src='/icons/phone.svg'
                          width={14}
                          height={14}
                          alt='right'
                        />
                      </div>
                      <p className='font-primary-light pl-2 text-xs md:text-base'>
                        {formatPhoneNumber(agent?.phone_1_display)}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className='md:px-5 md:pb-5 md:pt-2 hidden'>
            <div className='mt-3'>
              <ControlledTextInputV7<FormData, 'name'>
                control={control}
                placeholder='Name'
                rules={{ required: 'Name is required' }}
                name='name'
                shouldUnregister={false}
              />
            </div>
            <div className='mt-3'>
              <ControlledTextInputV7<FormData, 'emailAddress'>
                control={control}
                placeholder='Email Address'
                rules={{ required: 'Email address is required' }}
                name='emailAddress'
                shouldUnregister={false}
              />
            </div>
            <div className='mt-3'>
              <ControlledTextInputV7<FormData, 'phoneNumber'>
                control={control}
                placeholder='Phone Number'
                rules={{ required: 'Phone number is required' }}
                name='phoneNumber'
                shouldUnregister={false}
              />
            </div>
            <div className='my-3'>
              <ControlledTextAreaInputV7<FormData, 'message'>
                control={control}
                rows={3}
                name='message'
                placeholder='Write a message'
                rules={{
                  required: 'Message is required',
                }}
                shouldUnregister={false}
              />
            </div>
            <Button
              title={isSubmitSuccessful ? 'Submitted!' : 'Send Message'}
              variant='black'
              fullWidth
              icon={
                <Image
                  src='/icons/rightArrow.svg'
                  width={22}
                  height={22}
                  alt='right'
                />
              }
              isLoading={isSubmitting}
              disabled={isSubmitting || isSubmitSuccessful}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default GeminiContactAgentCard;
