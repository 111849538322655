import classNames from 'classnames';
import { FC } from 'react';
import {
  calculateCostForProperty,
  getPercentageBarAmount,
} from '../../utils/PropertiesUtils';
import GeminiPieChart from './GeminiPieChart';

interface IGeminiTableRow {
  label: string;
  value: string | number;
  color?: string;
  variant?: 'primary' | 'secondary';
}

interface IGeminiMonthlyCostPieChartCardProps {
  series: number[];
  propertyInterest: number;
  propertyTax: number;
  homeownersInsurance: number;
  hoa: number;
  totalMonthlyPrice: number;
}

const DataTableRow: FC<IGeminiTableRow> = ({
  color,
  label,
  value,
  variant = 'primary',
}) => {
  const variantMap = {
    primary: {
      textOpacity: 'text-opacity-100',
      labelTextSize: 'text-base',
      valueTextSize: 'text-lg',
    },
    secondary: {
      textOpacity: 'text-opacity-70',
      labelTextSize: 'text-sm',
      valueTextSize: 'text-sm',
    },
  };

  const { textOpacity, labelTextSize, valueTextSize } = variantMap[variant];

  return (
    <div
      className={classNames('flex items-center justify-between', textOpacity)}
    >
      <div className='flex items-center space-x-1.5'>
        {!!color && (
          <div
            className='h-2.5 w-2.5 rounded-full'
            style={{ background: color }}
          />
        )}
        <span
          className={classNames(
            'font-inter text-off-white',
            labelTextSize,
            textOpacity
          )}
        >
          {label}
        </span>
      </div>
      <span
        className={classNames(
          'font-inter text-off-white text-right',
          valueTextSize,
          textOpacity
        )}
      >
        {value}
      </span>
    </div>
  );
};

const GeminiMonthlyCostPieChartCard: FC<
  IGeminiMonthlyCostPieChartCardProps
> = ({
  propertyInterest,
  propertyTax,
  homeownersInsurance,
  hoa,
  totalMonthlyPrice,
  series,
}) => {
  const calculatedPropertyInterest = calculateCostForProperty(propertyInterest);
  const calculatedHoa = calculateCostForProperty(hoa);
  const calculateHOI = calculateCostForProperty(homeownersInsurance);
  const calculatedPropertyTax = calculateCostForProperty(propertyTax);
  const calculatedTMP = calculateCostForProperty(totalMonthlyPrice);
  const calculatedOther = calculateCostForProperty(homeownersInsurance + hoa);

  return (
    <div className='w-full xl:py-12 py-5 px-5 xl:px-10 bg-cobalt max-w-[450px]'>
      <div className='w-full flex items-center justify-center'>
        <GeminiPieChart
          series={series.map((num) =>
            getPercentageBarAmount(num, totalMonthlyPrice)
          )}
        />
      </div>
      <div className='mt-11'>
        <DataTableRow
          color='#00FBF0'
          label='Principal & Interest'
          value={calculatedPropertyInterest}
        />
        <div className='border border-light-gray-8 my-[14px]' />
        <DataTableRow
          color='#FF557E'
          label=' Property Taxes'
          value={calculatedPropertyTax}
        />
        <div className='border border-light-gray-8 my-[14px]' />
        <div className='flex flex-col gap-[14px]'>
          <DataTableRow color='#263993' label='Other' value={calculatedOther} />
          <DataTableRow
            variant='secondary'
            label='Homeowners Insurance'
            value={calculateHOI}
          />
          <DataTableRow variant='secondary' label='HOA' value={calculatedHoa} />
        </div>
        <div className='border border-light-gray-8 my-[14px]' />
        <div className='flex justify-between items-center mt-1.5'>
          <h5 className='font-telegraf text-xl xl:text-2xl text-off-white'>
            Total
          </h5>
          <h4 className='text-[26px] xl:text-4xl text-blue-1 font-telegraf'>
            {calculatedTMP}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default GeminiMonthlyCostPieChartCard;
