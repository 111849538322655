import { FC } from 'react';
import { ListingDetailResponse } from '../../../openapi/wanderer';
import GeminiPropertyDetailLabelValue from './GeminiPropertyDetailLabelValue';

interface IGeminiPropertyOfficeInfoCardProps {
  property: ListingDetailResponse;
}

const GeminiPropertyOfficeInfoCard: FC<IGeminiPropertyOfficeInfoCardProps> = ({
  property,
}) => {
  return (
    <div>
      <h4 className='font-telegraf text-[26px] xl:text-4xl text-cobalt'>
        Office Info
      </h4>

      <div className='flex flex-col gap-[14px] mt-5'>
        <GeminiPropertyDetailLabelValue
          label='Listing Broker:'
          value={property.fullListing?.listingOffice?.name || 'N/A'}
        />
        <GeminiPropertyDetailLabelValue
          label='Listing Agent:'
          value={property.fullListing?.listingAgent?.name || 'N/A'}
        />
      </div>
    </div>
  );
};

export default GeminiPropertyOfficeInfoCard;
