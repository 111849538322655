/* eslint-disable @typescript-eslint/explicit-function-return-type */
import classNames from 'classnames';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form';
import { EnumMap } from '../../types';
import ErrorMessage from '../ErrorMessage';

type SelectInputVariantType = 'default' | 'gemini';

export interface ISelectOption {
  value: string;
  label: string;
  disabled?: boolean;
}

interface ControlledSelectV7InputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  placeholder?: string;
  options: Array<ISelectOption>;
  readOnly?: boolean;
  variant?: SelectInputVariantType;
}

const ControlledSelectInputV7 = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  placeholder,
  options,
  readOnly,
  shouldUnregister = true,
  variant = 'default',
  ...rest
}: ControlledSelectV7InputProps<TFieldValues, TName>) => {
  const inputLabelVariantClassMap: EnumMap<SelectInputVariantType, string> = {
    default: 'text-lg font-medium text-gray-400',
    gemini: 'font-telegraf font-normal text-lg text-cobalt',
  };

  const inputContainerVariantClassMap: EnumMap<SelectInputVariantType, string> =
    {
      default:
        'p-1.5 border-gray-200 rounded focus:ring-dark focus:border-dark',
      gemini:
        'bg-off-white py-1.5 px-[14px] border-[1.5px] border-cobalt focus:border-cobalt font-inter text-base text-cobalt',
    };

  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { onChange, value, name, onBlur },
        fieldState: { error },
      }) => (
        <div className='space-y-1 w-full'>
          {label && (
            <label className='inline-block font-primary-regular' htmlFor={name}>
              <span className={inputLabelVariantClassMap[variant]}>
                {label}
              </span>
            </label>
          )}
          <select
            id={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            placeholder={placeholder}
            className={classNames(
              'appearance-none bg-white focus:outline-none focus:ring-0 w-full',
              inputContainerVariantClassMap[variant],
              {
                'bg-gray-50 pointer-events-none': readOnly,
              }
            )}
          >
            {options.map(({ value, label, disabled }) => (
              <option key={value} value={value} disabled={disabled}>
                {label}
              </option>
            ))}
          </select>
          {error && <ErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ControlledSelectInputV7;
