/* eslint-disable react/jsx-no-useless-fragment */
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FC } from 'react';
import { Features } from '../../../openapi/wanderer';
import GeminiPropertyDetailLabelValue from './GeminiPropertyDetailLabelValue';

const featureCategories: (keyof Features)[] = [
  'bedrooms',
  'bathrooms',
  'parking',
  'garage',
  'heating',
  'laundry',
  'cooling',
];

interface IGeminiPropertyFeaturesCardProps {
  features: Features;
}

const GeminiPropertyFeaturesCard: FC<IGeminiPropertyFeaturesCardProps> = ({
  features,
}) => {
  const hasFeatures = featureCategories.some(
    (category) => features[category]?.length
  );

  if (!hasFeatures) return null;

  return (
    <div>
      <h4 className='font-telegraf text-[26px] xl:text-4xl text-cobalt'>
        Features
      </h4>

      <div className='w-full grid md:grid-cols-2 grid-cols-1 md:gap-x-[60px] gap-y-[14px] mt-5'>
        {featureCategories.map((category) => (
          <GeminiMlsFeatureContainer
            key={category}
            features={features[category]}
          />
        ))}
      </div>
    </div>
  );
};

const GeminiMlsFeatureContainer: FC<{ features: string[] }> = ({
  features,
}) => {
  if (!features?.length) return null;

  return (
    <>
      {features?.map((feature) => {
        const [label, value] = feature.split(':');

        return (
          <GeminiPropertyDetailLabelValue
            key={feature}
            value={value || 'N/A'}
            label={`${label}:`}
            leftIcon={faCheck}
          />
        );
      })}
    </>
  );
};

export default GeminiPropertyFeaturesCard;
