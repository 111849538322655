/* eslint-disable @next/next/no-img-element */
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { NextPage } from 'next';
import React, { useEffect, useRef, useState } from 'react';
import { IPhoto } from '../../../types/real-api-types';
import GeminiCarousel from '../GeminiCarousel';

interface GeminiPropertyImagesProps {
  propertyImages: IPhoto[];
}

const GeminiPropertyImages: NextPage<GeminiPropertyImagesProps> = ({
  propertyImages,
}) => {
  const modalRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [openMobileView, setOpenMobileView] = useState(false);

  useEffect(() => {
    if (isOpen) {
      // Prevent body scroll
      document.body.style.overflow = 'hidden';

      const handleEsc = (event: KeyboardEvent): void => {
        if (event.key === 'Escape') {
          setIsOpen(false);
        }
      };

      document.addEventListener('keydown', handleEsc);

      return () => {
        document.removeEventListener('keydown', handleEsc);
        document.body.style.overflow = ''; // Re-enable body scroll
      };
    }
  }, [isOpen]);

  return (
    <div className='flex flex-col w-full'>
      {isOpen && (
        <div
          className={classNames(
            'md:block hidden fixed inset-0 overflow-hidden z-50 bg-black bg-opacity-60 transform ease-in-out w-screen h-screen',
            isOpen
              ? 'transition-opacity opacity-100 duration-500 translate-y-0'
              : 'transition-opacity opacity-0 translate-y-full'
          )}
        >
          <section className='md:m-11 delay-400' ref={modalRef}>
            <GeminiCarousel
              propertyImages={propertyImages}
              onClose={() => setIsOpen(false)}
            />
          </section>
        </div>
      )}
      {propertyImages.length > 0 && (
        <div className='md:flex hidden flex-row h-96 relative'>
          <div
            className={classNames(
              'relative h-96',
              propertyImages.length > 2 ? 'w-9/12 mr-2.5' : 'w-full'
            )}
          >
            <img
              src={propertyImages[0].thumbnail}
              alt='Property'
              className='w-full h-full object-cover'
            />
          </div>
          {propertyImages.length > 2 && (
            <div className='grid grid-rows-2 gap-2.5 h-96 w-3/12 relative'>
              <div className='relative'>
                <img
                  src={propertyImages[1].thumbnail}
                  alt='Property 2'
                  className='w-full h-full object-cover'
                />
              </div>
              <div className='relative'>
                <img
                  src={propertyImages[2].thumbnail}
                  alt='Property 3'
                  className='w-full h-full object-cover'
                />
                <div
                  onClick={() => setIsOpen(true)}
                  className='md:inline-flex hidden absolute bottom-5 left-5 px-1.5 py-0.5 bg-white bg-opacity-70 rounded-[5px] z-10 cursor-pointer'
                >
                  <p className='font-inter text-base text-cobalt'>
                    See all {propertyImages.length} Photos
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <div className='relative md:hidden'>
        <GeminiCarousel
          propertyImages={propertyImages}
          onClose={() => undefined}
        />
        <div
          onClick={() => setOpenMobileView(true)}
          className='absolute bottom-[54px] right-[30px] px-1.5 py-0.5 bg-white bg-opacity-70 rounded-[5px] z-10 cursor-pointer'
        >
          <p className='font-inter text-base text-cobalt'>
            See all {propertyImages.length} Photos
          </p>
        </div>
        {openMobileView && (
          <div className='fixed inset-0 overflow-hidden z-50 bg-off-white transform ease-in-out w-screen h-screen'>
            <button
              onClick={() => setOpenMobileView(false)}
              className='p-5 font-telegraf text-base capitalize text-cobalt flex items-center space-x-2'
            >
              <FontAwesomeIcon icon={faArrowLeft} />
              <span>back to Results</span>
            </button>
            <div className='h-[calc(100vh-80px)] flex flex-col space-y-3 overflow-y-scroll'>
              {propertyImages?.map((image) => (
                <div className='relative h-80 w-auto' key={image.index}>
                  <img
                    src={image.thumbnail}
                    alt='Property'
                    className='w-full h-full bg-contain'
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GeminiPropertyImages;
