import React from 'react';
import { ListingDetailResponse } from '../../../openapi/wanderer';
import GeminiPropertyDetailLabelValue from './GeminiPropertyDetailLabelValue';

interface GeminiPropertyOverviewCardProps {
  property: ListingDetailResponse;
}

const GeminiPropertyOverviewCard: React.FC<GeminiPropertyOverviewCardProps> = ({
  property,
}) => {
  const { description, propertyType, yearBuilt } = property.fullListing || {};

  return (
    <div>
      <h4 className='font-telegraf text-[26px] xl:text-4xl text-cobalt'>
        Overview
      </h4>
      {!!description && (
        <p className='mt-5 text-opacity-70 text-cobalt font-inter text-lg w-11/12'>
          {description}
        </p>
      )}

      <div className='flex flex-col md:flex-row gap-[14px] md:gap-[60px] mt-10'>
        <GeminiPropertyDetailLabelValue
          label='Property Type:'
          value={propertyType || 'N/A'}
        />
        <GeminiPropertyDetailLabelValue
          label='Year Built:'
          value={yearBuilt || 'N/A'}
        />
      </div>
    </div>
  );
};

export default GeminiPropertyOverviewCard;
