import classNames from 'classnames';
import {
  Controller,
  FieldValues,
  UseControllerProps,
  FieldPath,
} from 'react-hook-form';
import { FormFieldTooltipIndexProps } from '../../types';
import FormErrorMessage from '../FormErrorMessage';

interface ControlledTextAreaInputV7Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  subLabel?: string;
  placeholder?: string;
  rows?: number;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  noScroll?: boolean;
  noResize?: boolean;
  borderPrimary?: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const ControlledTextAreaInputV7 = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  name,
  placeholder,
  label,
  subLabel,
  startAdornment,
  endAdornment,
  noScroll = false,
  noResize = false,
  borderPrimary = false,
  rows = 1,
  shouldUnregister = true,
  ...rest
}: ControlledTextAreaInputV7Props<TFieldValues, TName>) => {
  return (
    <Controller
      control={control}
      name={name}
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { name, value, onChange, onBlur, ref },
        fieldState: { error },
      }) => (
        <div className='space-y-1 w-full'>
          {label && (
            <label className='inline-block' htmlFor={name}>
              <span>{label}</span>
              {!!subLabel && (
                <span className='font-primary-regular text-sm text-gray-400 ml-1'>
                  {subLabel}
                </span>
              )}
            </label>
          )}
          <div
            className={classNames(
              'flex border rounded bg-white',
              borderPrimary ? 'border-primary' : 'border-gray-200'
            )}
          >
            {startAdornment && <div>{startAdornment}</div>}
            <textarea
              id={name}
              ref={ref}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              name={name}
              placeholder={placeholder}
              rows={rows}
              className={classNames(
                'appearance-none p-1.5 focus:outline-none focus:ring-0 w-full border-none',
                { 'overflow-hidden': noScroll },
                { 'resize-none': noResize }
              )}
            />
            {endAdornment && <div>{endAdornment}</div>}
          </div>
          {error && <FormErrorMessage message={error.message} />}
        </div>
      )}
    />
  );
};

export default ControlledTextAreaInputV7;
