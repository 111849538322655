import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { getPercentageBarAmount } from '../../utils/PropertiesUtils';
import ControlledSelectInputV7 from '../controlled-inputs/ControlledSelectInputV7';
import ControlledTextInput from '../controlled-inputs/ControlledTextInput';
import GeminiMonthlyCostPieChartCard from './GeminiMonthlyCostPieChartCard';
import GeminiTextInputAdornment from './Inputs/GeminiTextInputAdornment';

enum DEFAULT_PROPERTY_CONSTANTS {
  DEFAULT_DOWN_PAYMENT = 50000,
  DEFAULT_HOME_PRICE = 250000,
  DEFAULT_INTEREST_RATE = 5,
  DEFAULT_LOAN = 30,
}

interface IGeminiMonthlyCostCardProps {
  listPrice: number;
  setEstimatedMonthlyPrice?(amount: number): void;
}

interface formData {
  homePrice: string;
  downPayment: string;
  interestRate: string;
  loan: string;
  propertyTax: string;
  homeownersInsurance: string;
  hoaFees: string;
}

const GeminiMonthlyCostCard: React.FC<IGeminiMonthlyCostCardProps> = ({
  listPrice,
  setEstimatedMonthlyPrice,
}) => {
  const { control, watch } = useForm<formData>({
    defaultValues: {
      homePrice: (
        listPrice || DEFAULT_PROPERTY_CONSTANTS.DEFAULT_HOME_PRICE
      ).toString(),
      downPayment: (
        Math.floor(listPrice / 5) ||
        DEFAULT_PROPERTY_CONSTANTS.DEFAULT_DOWN_PAYMENT
      ).toString(),
      interestRate: DEFAULT_PROPERTY_CONSTANTS.DEFAULT_INTEREST_RATE.toString(),
      loan: DEFAULT_PROPERTY_CONSTANTS.DEFAULT_LOAN.toString(),
    },
  });

  const parseAmountFromFormField = (key: keyof formData): number =>
    parseFloat(watch(key) || '0');

  const homePrice = parseAmountFromFormField('homePrice');
  const downPayment = parseAmountFromFormField('downPayment');
  const interestRate = parseAmountFromFormField('interestRate') / 100;
  const loan = parseAmountFromFormField('loan');
  const propertyTax = parseAmountFromFormField('propertyTax');
  const homeownersInsurance = parseAmountFromFormField('homeownersInsurance');
  const hoaFees = parseAmountFromFormField('hoaFees');

  const principalLoanAmount = homePrice - downPayment;
  const monthlyRate = interestRate / 12;
  const numberOfPayments = loan * 12;

  const monthlyPrice =
    principalLoanAmount *
      ((monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) /
        (Math.pow(1 + monthlyRate, numberOfPayments) - 1)) || 0;

  const totalMonthlyPrice =
    monthlyPrice + propertyTax + homeownersInsurance + hoaFees;

  const downPaymentPercentage = ((downPayment / homePrice) * 100).toFixed(0);

  const series = [monthlyPrice, propertyTax, homeownersInsurance + hoaFees];

  useEffect(() => {
    if (setEstimatedMonthlyPrice) {
      setEstimatedMonthlyPrice(totalMonthlyPrice);
    }
  }, [totalMonthlyPrice, setEstimatedMonthlyPrice]);

  return (
    <div className='w-full'>
      <h4 className='font-telegraf xl:text-4xl text-[26px] text-cobalt mb-[30px]'>
        Monthly Cost
      </h4>

      <div className='flex flex-wrap sm:gap-[70px] lg:flex-row flex-col'>
        <div className='flex-[2] bg-white px-[15px] py-10 sm:p-10 flex flex-col gap-[18px] sm:gap-[30px] shadow-md'>
          <ControlledTextInput
            label='Home Price'
            name='homePrice'
            type='number'
            placeholder='Home Price'
            control={control}
            rules={{ required: 'Required' }}
            startAdornment={<GeminiTextInputAdornment text='$' />}
            variant='gemini'
          />
          <ControlledTextInput
            label='Down Payment'
            name='downPayment'
            type='number'
            placeholder='Down Payment'
            control={control}
            rules={{ required: 'Required' }}
            variant='gemini'
            startAdornment={<GeminiTextInputAdornment text='$' />}
            endAdornment={
              <GeminiTextInputAdornment
                text={`${downPaymentPercentage.toString()}%`}
                isRightAdornment
                className='border-l-[1.5px] border-cobalt'
              />
            }
          />
          <div className='flex gap-5 items-center sm:flex-row flex-col'>
            <ControlledTextInput
              label='Interest Rate (%)'
              name='interestRate'
              type='number'
              placeholder='Interest Rate'
              control={control}
              rules={{ required: 'Required' }}
              variant='gemini'
            />
            <ControlledSelectInputV7
              options={[
                { label: '5 Years', value: '5' },
                {
                  label: '10 Years',
                  value: '10',
                },
                { label: '15 Years', value: '15' },
                {
                  label: '30 Years',
                  value: '30',
                },
              ]}
              name='loan'
              control={control}
              label='Loan'
              placeholder='select options'
              variant='gemini'
            />
          </div>
          <div className='flex flex-col gap-5'>
            <h4 className='font-telegraf xl:text-4xl text-[26px] text-cobalt'>
              Optional:
            </h4>
            <ControlledTextInput
              label='Property Tax'
              name='propertyTax'
              type='number'
              control={control}
              startAdornment={<GeminiTextInputAdornment text='$' />}
              endAdornment={
                <GeminiTextInputAdornment text='/mo' isRightAdornment />
              }
              variant='gemini'
            />

            <ControlledTextInput
              label='Homeowners insurance'
              name='homeownersInsurance'
              type='number'
              control={control}
              startAdornment={<GeminiTextInputAdornment text='$' />}
              endAdornment={
                <GeminiTextInputAdornment text='/mo' isRightAdornment />
              }
              variant='gemini'
            />

            <ControlledTextInput
              label='HOA Fees'
              name='hoaFees'
              type='number'
              control={control}
              startAdornment={<GeminiTextInputAdornment text='$' />}
              endAdornment={
                <GeminiTextInputAdornment text='/mo' isRightAdornment />
              }
              variant='gemini'
            />
          </div>
        </div>

        <div className='flex-1 sm:mt-0 mt-10'>
          <GeminiMonthlyCostPieChartCard
            hoa={hoaFees}
            homeownersInsurance={homeownersInsurance}
            propertyInterest={monthlyPrice}
            propertyTax={propertyTax}
            series={series.map((i) =>
              getPercentageBarAmount(i, totalMonthlyPrice)
            )}
            totalMonthlyPrice={totalMonthlyPrice}
          />
        </div>
      </div>
    </div>
  );
};

export default GeminiMonthlyCostCard;
