import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { FC } from 'react';

interface PropertyDetailLabelValueProps {
  label: string;
  value: string | number;
  leftIcon?: IconProp;
}

const GeminiPropertyDetailLabelValue: FC<PropertyDetailLabelValueProps> = ({
  label,
  value,
  leftIcon,
}) => {
  const isSingleRowLayout = typeof value === 'string' && value.length < 4;

  return (
    <div className='flex items-start text-cobalt'>
      {!!leftIcon && (
        <div className='flex items-center justify-center xl:h-8 xl:w-8 h-7 w-7'>
          <FontAwesomeIcon icon={leftIcon} size='lg' className='mr-2.5' />
        </div>
      )}
      <div
        className={classNames(
          'flex flex-col md:flex-row md:items-center gap-2.5 flex-wrap',
          { '!flex-row': isSingleRowLayout }
        )}
      >
        <h5 className='font-telegraf font-normal xl:text-2xl text-xl'>
          {label}
        </h5>
        <span className='font-inter text-lg text-cobalt text-opacity-70'>
          {value}
        </span>
      </div>
    </div>
  );
};

export default GeminiPropertyDetailLabelValue;
