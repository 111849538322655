import React from 'react';
import classNames from 'classnames';

interface GeminiTextInputAdornmentProps {
  text?: string;
  isRightAdornment?: boolean;
  className?: string;
}

const GeminiTextInputAdornment: React.FC<GeminiTextInputAdornmentProps> = ({
  text,
  isRightAdornment = false,
  className,
}) => {
  return (
    <div
      className={classNames(
        'h-full flex items-center justify-center px-0 py-1.5 text-cobalt',
        isRightAdornment && 'pl-[14px]',
        className
      )}
    >
      {!!text && <span className='font-inter text-base'>{text}</span>}
    </div>
  );
};

export default GeminiTextInputAdornment;
