import Image from 'next/image';
import canadalogo from '../../../assets/img/canadalogo.svg';
import { ListingDetailResponse } from '../../../openapi/wanderer';
import {
  isCASiteOnly,
  isMultiRegionSite,
  isUSSiteOnly,
} from '../../../utils/SiteConfiguration';
import GeminiMlsDisclaimer from './GeminiMlsDisclaimer';
import GeminiPropertyDetailLabelValue from './GeminiPropertyDetailLabelValue';

interface IGeminiPropertyMlsInfoCardProps {
  property: ListingDetailResponse;
}

const GeminiPropertyMlsInfoCard: React.FC<IGeminiPropertyMlsInfoCardProps> = ({
  property,
}) => {
  const isSupportedCountry = isMultiRegionSite();
  const isUSOnly = isUSSiteOnly();
  const isCAOnly = isCASiteOnly();

  let mlsNumber = 'N/A';

  if ((isSupportedCountry || isUSOnly) && property?.fullListing?.id)
    mlsNumber = property.fullListing.id;
  else if (isCAOnly && property?.fullListing?.mlsId)
    mlsNumber = property.fullListing.mlsId;
  else mlsNumber = 'N/A';

  return (
    <div>
      <h4 className='font-telegraf text-[26px] xl:text-4xl text-cobalt'>
        MLS Info
      </h4>

      <div className='flex md:flex-row flex-col xl:gap-[60px] gap-[14px] mt-5 flex-wrap'>
        <GeminiPropertyDetailLabelValue label='MLS #:' value={mlsNumber} />
        <GeminiPropertyDetailLabelValue
          label='MLS Name:'
          value={property?.mlsInfo?.name || 'N/A'}
        />
        <GeminiPropertyDetailLabelValue
          label='Status:'
          value={property?.fullListing?.status || 'N/A'}
        />
      </div>

      {isCAOnly && (
        <div className='mt-[14px]'>
          <a
            target='_blank'
            href={`https://www.realtor.ca/real-estate/${property?.fullListing?.id}/1`}
            rel='noreferrer'
          >
            <Image src={canadalogo} width={180} height={60} alt='down' />
          </a>
        </div>
      )}
      <GeminiMlsDisclaimer property={property} />
    </div>
  );
};

export default GeminiPropertyMlsInfoCard;
