import React, { FC } from 'react';
import { ApexOptions } from 'apexcharts';
import dynamic from 'next/dynamic';

const options: ApexOptions = {
  colors: ['#00FBF0', '#FF557E', '#263993'],
  dataLabels: { enabled: false },
  tooltip: { enabled: false },
  legend: { show: false },
  // remove white border around the chart
  stroke: { width: 0 },
  plotOptions: {
    pie: {
      expandOnClick: false,
      // make bars thinner for donut chart
      donut: { size: '74%' },
    },
  },
  states: {
    // disable change color on hover
    hover: { filter: { type: 'none' } },
    // disable click effect on legend
    active: { filter: { type: 'none' } },
  },
};

const Chart = dynamic(() => import('react-apexcharts'), { ssr: false });

interface IGeminiPieChartProps {
  series: number[];
}

const GeminiPieChart: FC<IGeminiPieChartProps> = ({ series }) => {
  return <Chart options={options} series={series} type='donut' width='265' />;
};

export default GeminiPieChart;
