import classNames from 'classnames';
import Image from 'next/image';

interface DrawerProps {
  children: any;
  isOpen: boolean;
  setIsOpen: any;
}

const Drawer: React.FC<DrawerProps> = ({ children, isOpen, setIsOpen }) => {
  return (
    <main
      className={classNames(
        ' fixed overflow-hidden z-40 bg-gray-900 bg-opacity-80 inset-0 transform ease-in-out ' +
          (isOpen
            ? ' transition-opacity opacity-100 duration-500 translate-y-0 '
            : ' transition-all delay-300 opacity-0 translate-y-full')
      )}
    >
      <section
        className={
          ' w-screen max-w-lg right-0 bottom-0 absolute bg-white rounded-t-xl h-fit mt-16 shadow-xl delay-400 duration-500 ease-in-out transition-all transform  ' +
          (isOpen ? ' translate-y-0 ' : ' translate-y-full ')
        }
      >
        <div className='relative overflow-y-scroll  pb-4 flex flex-col space-y-4  w-screen  h-fit'>
          <button
            onClick={() => {
              setIsOpen(false);
            }}
            className='absolute right-4 top-4 font-bold text-lg'
          >
            <Image src='/icons/close.svg' width={16} height={16} alt='close' />
          </button>
          {children}
        </div>
      </section>
    </main>
  );
};

export default Drawer;
