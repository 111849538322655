import React, { useState } from 'react';
import { Popover } from 'react-tiny-popover';
import classNames from 'classnames';
import Drawer from '../Drawer';

type ColorType = 'primary' | 'danger' | 'warning' | 'success';

export interface GeminiContentOptionsItem {
  icon?: React.ReactElement;
  text: string;
  colorType?: ColorType;
  onClick(): void;
  divider?: boolean;
  disabled?: boolean;
}

interface GeminiContentOptionsProps {
  icon?: React.ReactElement;
  label?: string;
  items: GeminiContentOptionsItem[];
  openIcon?: React.ReactElement;
  containerClassName?: string;
}

const GeminiContentOptions: React.FC<GeminiContentOptionsProps> = ({
  label,
  icon,
  items,
  openIcon,
  containerClassName,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const colorTypeClassNameMap: { [type in ColorType]: string } = {
    primary: 'text-primary hover:bg-slate-200 hover:bg-opacity-10',
    danger: 'text-error hover:bg-error hover:bg-opacity-10',
    warning: 'text-warning hover:bg-warning hover:bg-opacity-10',
    success: 'text-success hover:bg-success hover:bg-opacity-10',
  };

  return (
    <>
      <div className='block md:hidden'>
        <Drawer isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen}>
          <div className='w-full text-base font-inter px-7 '>Share By</div>
          {items.map((item) => (
            <div
              key={item.text}
              className={classNames(
                'flex items-center py-1 px-8 text-danger text-gray-700',
                item.colorType
                  ? colorTypeClassNameMap[item.colorType]
                  : 'hover:bg-slate-100',
                { 'border-t': item.divider },
                item.disabled
                  ? 'text-gray-400 cursor-default'
                  : 'cursor-pointer'
              )}
              onClick={() => {
                if (!item.disabled) {
                  item.onClick();
                  setIsDrawerOpen(false);
                }
              }}
            >
              <span className='pr-2'>{item.icon}</span>
              <span>{item.text}</span>
            </div>
          ))}
        </Drawer>
        <div
          className='flex items-center cursor-pointer'
          onClick={() => setIsDrawerOpen(true)}
        >
          {openIcon
            ? !isDrawerOpen
              ? icon && <span>{icon}</span>
              : openIcon && <span>{openIcon}</span>
            : icon && <span>{icon}</span>}

          {label && (
            <span
              className={classNames('text-base text-inter -mt-1', {
                'pl-2': icon,
              })}
            >
              {label}
            </span>
          )}
        </div>
      </div>
      <div className='hidden md:block'>
        <Popover
          isOpen={isOpen}
          positions={['bottom']}
          align='end'
          containerClassName={containerClassName}
          onClickOutside={() => setIsOpen(false)}
          content={
            <div className='border-0 rounded-xl drop-shadow-dropDown bg-white py-4'>
              {items.map((item) => (
                <div
                  key={item.text}
                  className={classNames(
                    'flex flex-row items-center py-2 px-6 text-danger text-gray-700',
                    item.colorType
                      ? colorTypeClassNameMap[item.colorType]
                      : 'hover:bg-slate-100',
                    { 'border-t': item.divider },
                    item.disabled
                      ? 'text-gray-400 cursor-default'
                      : 'cursor-pointer'
                  )}
                  onClick={() => {
                    if (!item.disabled) {
                      item.onClick();
                      setIsOpen(false);
                    }
                  }}
                >
                  <span className='pr-2'>{item.icon}</span>
                  <span>{item.text}</span>
                </div>
              ))}
            </div>
          }
        >
          <div
            className='flex items-center cursor-pointer'
            onClick={() => setIsOpen(!isOpen)}
          >
            {openIcon
              ? !isOpen
                ? icon && <span>{icon}</span>
                : openIcon && <span>{openIcon}</span>
              : icon && <span>{icon}</span>}
            {label && (
              <span
                className={classNames('text-base text-inter -mt-1.5', {
                  'pl-2': icon,
                })}
              >
                {label}
              </span>
            )}
          </div>
        </Popover>
      </div>
    </>
  );
};

export default GeminiContentOptions;
