import classNames from 'classnames';
import React from 'react';
import { LegaleseElement } from '../../../openapi/wanderer';
import GeminiMLSDisplayText from './GeminiMLSDisplayText';

interface GeminiPropertyCourtesyProps {
  legalese: Array<LegaleseElement>;
  attributionContact?: string;
  className?: string;
}

const GeminiPropertyCourtesy: React.FC<GeminiPropertyCourtesyProps> = ({
  legalese,
  attributionContact,
  className,
}) => {
  const courtesy = (legalese || []).find(
    (v) => v?.disclaimerType === 'courtesy'
  );
  const nwmlsLegalese = (legalese || []).find(
    (v) => v?.mlsId === 'nwmls' && !!v?.asset
  );

  const njmlsLease = (legalese || []).find(
    (v) => v?.mlsId === 'njmls' && !!v?.asset
  );

  if (!courtesy?.text) {
    return null;
  }

  const mlsToDisplay = njmlsLease || nwmlsLegalese;
  const mlsName =
    mlsToDisplay?.mlsId === 'njmls'
      ? 'NJMLS'
      : mlsToDisplay?.mlsId === 'nwmls'
      ? 'NWMLS'
      : null;

  return (
    <div
      className={classNames(
        'flex flex-wrap font-inter text-sm font-normal text-cobalt self-stretch text-opacity-70',
        className
      )}
      data-testid='property-courtesy'
    >
      <span>
        {courtesy?.text}
        {!!attributionContact && attributionContact}
        <GeminiMLSDisplayText mls={mlsToDisplay} mlsName={mlsName} />
      </span>
    </div>
  );
};

export default GeminiPropertyCourtesy;
