/* eslint-disable @next/next/no-img-element */
import classNames from 'classnames';
import React from 'react';
import { LegaleseElement } from '../../../openapi/wanderer';

export interface GeminiMLSDisplayTextProps {
  mls: LegaleseElement | null;
  mlsName: string;
}

const GeminiMLSDisplayText: React.FC<GeminiMLSDisplayTextProps> = ({
  mls,
  mlsName,
}) => {
  if (!mls || !mlsName) return null;

  const isMLSAssetWide =
    mls.asset.width > 0 && mls.asset.width > mls.asset.height * 1.5;

  return (
    <>
      {' '}
      {mlsName}
      {!!mls.asset?.url && (
        <img
          src={mls?.asset?.url}
          alt='MLS Asset'
          className={classNames(
            'w-auto inline-block -mt-0.5 ml-0.5',
            isMLSAssetWide ? 'h-4' : 'h-5'
          )}
        />
      )}
    </>
  );
};

export default GeminiMLSDisplayText;
