import classNames from 'classnames';

export interface ITab {
  name: string;
  badgeContent?: string | number;
  subtitle?: string | number;
  TabComponent?: React.ReactNode;
  onClick?(): void;
  hidden?: boolean;
}

export interface IGeminiPropertyDetailTabsProps {
  tabs: ITab[];
  selected?: number;
  onChange(selected: number): void;
}

const GeminiPropertyDetailTabs: React.FC<IGeminiPropertyDetailTabsProps> = ({
  tabs,
  selected = 0,
  onChange,
}) => {
  return (
    <div className='max-w-[978px] mx-auto w-full flex border-b-[1.5px] overflow-x-auto'>
      {tabs.map(({ name, onClick, hidden }, index) => {
        const activeTab = index === selected;

        return (
          <button
            key={name}
            className={classNames(
              'flex flex-col !whitespace-nowrap focus:outline-none px-[30px] sm:px-10 py-1.5 text-xl md:text-2xl font-telegraf',
              { 'border-b-[1.5px] border-b-red-1': activeTab, hidden: hidden }
            )}
            onClick={() => {
              onChange(index);
              onClick?.();
            }}
          >
            {name}
          </button>
        );
      })}
    </div>
  );
};

export default GeminiPropertyDetailTabs;
