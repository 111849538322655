/* eslint-disable import/no-nodejs-modules */
import { ParsedUrlQuery } from 'querystring';
import {
  faBath,
  faBedFront,
  faRulerCombined,
  faShareNodes,
} from '@fortawesome/pro-light-svg-icons';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import { useRouter } from 'next/router';
import { FC, useEffect, useRef, useState } from 'react';
import useScrollSpy from 'react-use-scrollspy';
import Container from '../../../../components/Container';
import GeminiContactAgentCard from '../../../../components/Gemini/GeminiContactAgentCard';
import GeminiContentOptions from '../../../../components/Gemini/GeminiContentOptions';
import GeminiMonthlyCostCard from '../../../../components/Gemini/GeminiMonthlyCostCard';
import GeminiPropertyDetailTabs from '../../../../components/Gemini/GeminiPropertyDetailTabs';
import GeminiPropertyCourtesy from '../../../../components/Gemini/PropertyCard/GeminiPropertyCourtesy';
import GeminiPropertyFeaturesCard from '../../../../components/Gemini/PropertyDetails/GeminiPropertyFeaturesCard';
import GeminiPropertyImages from '../../../../components/Gemini/PropertyDetails/GeminiPropertyImages';
import GeminiPropertyMlsInfoCard from '../../../../components/Gemini/PropertyDetails/GeminiPropertyMlsInfoCard';
import GeminiPropertyOfficeInfoCard from '../../../../components/Gemini/PropertyDetails/GeminiPropertyOfficeInfoCard';
import GeminiPropertyOverviewCard from '../../../../components/Gemini/PropertyDetails/GeminiPropertyOverviewCard';
import LayoutRedesigned from '../../../../components/LayoutRedesigned';
import Seo from '../../../../components/Seo';
import { ITab } from '../../../../components/TabsPropertyDetails';
import useENVFeatureFlag from '../../../../hooks/useENVFeatureFlag';
import { PropertySearchControllerApi } from '../../../../openapi/artemis';
import { OfficesResponse } from '../../../../openapi/bff';
import {
  ListingDetailResponse,
  WandererControllerApi,
} from '../../../../openapi/wanderer';
import BFFApiService from '../../../../services/BFFApiService';
import ContentfulService from '../../../../services/ContentfulService';
import ErrorService from '../../../../services/ErrorService';
import { CommonPageDataProps } from '../../../../types';
import {
  displayAmount,
  MoneyValueCurrencyEnum,
} from '../../../../utils/CurrencyUtils';
import { scrollToTargetAdjusted } from '../../../../utils/DisplayHelpers';
import {
  getArtemisConfiguration,
  getWandererConfiguration,
} from '../../../../utils/OpenapiConfigurationUtils';
import {
  getAddressLine1,
  getAddressLine2,
  getPropertyDetailsFromArtemisListings,
  getShareOptionItems,
  isRental,
} from '../../../../utils/PropertiesUtils';
import {
  isMultiRegionSite,
  isUSSiteOnly,
  MultiRegionalSiteOnly,
  USSiteOnly,
} from '../../../../utils/SiteConfiguration';

const HEADER_SCROLL_OFFSET = -220;

const TAB_MAP_INDEX: Record<number, string> = {
  0: 'Overview',
  1: 'Features',
  2: 'Office Info',
  3: 'MLS Info',
  4: 'Monthly Costs',
};

interface PropertyPageParams extends ParsedUrlQuery {
  mlsId: string;
  mlsNumber: string;
  slug: string;
}

interface PropertyDetailPageProps extends CommonPageDataProps {
  property: ListingDetailResponse;
  allOffices: OfficesResponse;
}

const HorizontalSeparator: FC = () => (
  <div className='w-full h-[1.5px] bg-new-gray my-10' />
);

const PropertyDetailPage: NextPage<PropertyDetailPageProps> = ({
  countriesWithStates,
  property,
  footerContent,
  allOffices,
}) => {
  const [estimatedMonthlyPrice, setEstimatedMonthlyPrice] = useState<number>();
  const [isAtTop, setIsAtTop] = useState<boolean>(true);
  const router = useRouter();
  const backUrl = (router?.query?.backUrl as string) ?? '/listings';
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const doesFeaturesExist =
    !!property.fullListing?.features?.bedrooms?.length ||
    !!property.fullListing?.features?.bathrooms?.length ||
    !!property.fullListing?.features?.parking?.length ||
    !!property.fullListing?.features?.cooling?.length ||
    !!property.fullListing?.features?.garage?.length ||
    !!property.fullListing?.features?.heating?.length ||
    !!property.fullListing?.features?.laundry?.length;

  const isGeminiHeaderEnabled = useENVFeatureFlag(
    'NEXT_PUBLIC_GEMINI_HEADER_ENABLED'
  );

  const HEADER_HEIGHT = isGeminiHeaderEnabled ? 180 : 220;

  const checkIfAtTop = (): void => {
    setIsAtTop(window.scrollY === 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', checkIfAtTop);
    checkIfAtTop();
    return () => window.removeEventListener('scroll', checkIfAtTop);
  }, []);

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: HEADER_SCROLL_OFFSET,
  });

  const lotSize = property.fullListing?.lotSize?.acres
    ? `${property.fullListing?.lotSize?.acres} ac`
    : 'N/A';

  const houseSize = property.fullListing?.size
    ? `${property.fullListing?.size} sq.ft.`
    : 'N/A';

  const tabs: ITab[] = [
    {
      name: TAB_MAP_INDEX[0],
      onClick: () => scrollToTargetAdjusted(sectionRefs[0], HEADER_HEIGHT),
    },
    {
      name: TAB_MAP_INDEX[1],
      onClick: () => scrollToTargetAdjusted(sectionRefs[1], HEADER_HEIGHT),
      hidden: !doesFeaturesExist,
    },
    {
      name: TAB_MAP_INDEX[2],
      onClick: () => scrollToTargetAdjusted(sectionRefs[2], HEADER_HEIGHT),
    },
    {
      name: TAB_MAP_INDEX[3],
      onClick: () => scrollToTargetAdjusted(sectionRefs[3], HEADER_HEIGHT),
    },
    {
      name: TAB_MAP_INDEX[4],
      onClick: () => scrollToTargetAdjusted(sectionRefs[4], HEADER_HEIGHT),
      hidden: isRental(property.fullListing),
    },
  ];

  return (
    <div className='bg-off-white'>
      <LayoutRedesigned
        countriesWithStates={countriesWithStates}
        disclaimers={property.legalese}
        footerContent={footerContent}
        allOffices={allOffices}
      >
        <Seo
          title={property?.fullListing?.address?.deliveryLine}
          description={property?.fullListing?.description}
          ogImage={
            !!property?.fullListing?.images?.length
              ? property?.fullListing?.images[0]
              : undefined
          }
          ogDescription={property?.fullListing?.description}
          ogTitle={property?.fullListing?.address?.deliveryLine}
          imageUrl={
            !!property?.fullListing?.images?.length
              ? property?.fullListing?.images[0]
              : undefined
          }
        />

        <Container paddingVariant='sm'>
          <button
            className='mt-6 mb-[53px] md:my-10'
            onClick={() => router.push(backUrl)}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
            <span className='font-telegraf text-cobalt tracking-wide ml-2'>
              Back to Search
            </span>
          </button>

          <div className='block md:hidden mt-2.5 mb-[14px]'>
            <MultiRegionalSiteOnly>
              <GeminiPropertyCourtesy
                legalese={property?.legalese || []}
                attributionContact={property?.fullListing?.attributionContact}
              />
            </MultiRegionalSiteOnly>

            <USSiteOnly>
              <GeminiPropertyCourtesy
                legalese={property?.legalese || []}
                attributionContact={property?.fullListing?.attributionContact}
              />
            </USSiteOnly>
          </div>

          <GeminiPropertyImages
            propertyImages={
              property.fullListing?.images?.map((image, index) => ({
                index,
                large: image,
                small: image,
                medium: image,
                thumbnail: image,
              })) || []
            }
          />

          <div className='relative'>
            <div className='md:block hidden mt-[14px]'>
              <MultiRegionalSiteOnly>
                <GeminiPropertyCourtesy
                  legalese={property?.legalese || []}
                  attributionContact={property?.fullListing?.attributionContact}
                />
              </MultiRegionalSiteOnly>
              <USSiteOnly>
                <GeminiPropertyCourtesy
                  legalese={property?.legalese || []}
                  attributionContact={property?.fullListing?.attributionContact}
                />
              </USSiteOnly>
            </div>

            <div>
              <p className='font-inter font-bold text-cobalt uppercase tracking-[3.52px] mt-[70px] md:mt-[30px] mb-5 md:mb-2.5'>
                {isRental(property.fullListing) ? 'For Rent' : 'For Sale'}
              </p>

              <h3 className='font-telegraf text-[42px] md:text-5xl text-cobalt mb-5 md:mb-1.5 leading-[44px]'>
                {getAddressLine1(property.fullListing?.address)}
              </h3>
              <p className='text-cobalt font-inter text-opacity-70'>
                {getAddressLine2(property.fullListing?.address)}
              </p>

              <div className='mt-5 md:mt-10 flex gap-5 md:gap-10 md:flex-row flex-col'>
                {property.fullListing?.beds && (
                  <div className='flex items-center'>
                    <FontAwesomeIcon icon={faBedFront} size='xl' />
                    <p className='font-inter text-lg ml-1.5 text-cobalt'>
                      {property.fullListing?.beds} Beds
                    </p>
                  </div>
                )}
                {property.fullListing?.baths?.total && (
                  <div className='flex flex-row items-center'>
                    <FontAwesomeIcon icon={faBath} size='xl' />
                    <p className='font-inter text-lg ml-1.5 text-cobalt'>
                      {property.fullListing?.baths?.total} Baths
                    </p>
                  </div>
                )}
                <div className='flex flex-row items-center'>
                  <FontAwesomeIcon
                    icon={faRulerCombined}
                    className='rotate-90'
                    size='xl'
                  />
                  <p className='font-inter text-lg ml-1.5 text-cobalt'>
                    {`Lot Size: ${lotSize} | House Size: ${houseSize}`}
                  </p>
                </div>
              </div>

              <div className='mt-10 lg:hidden block'>
                <ShareContent
                  estimatedMonthlyPrice={estimatedMonthlyPrice}
                  property={property}
                />
              </div>
            </div>

            <div className='hidden lg:block absolute -right-[1.5px] -top-[14px] border-l-[1.5px] border-red-1 bg-off-white pl-[70px] pb-[70px] pt-[94px]'>
              <GeminiContactAgentCard
                price={{
                  amount: property.fullListing?.listPrice,
                  currency: MoneyValueCurrencyEnum.Usd,
                }}
                estimatedMonthlyPrice={{
                  amount: estimatedMonthlyPrice,
                  currency: MoneyValueCurrencyEnum.Usd,
                }}
                property={property}
              />
            </div>
          </div>

          <div
            className={classNames(
              'sticky top-[130px] w-full z-30 mt-10 lg:mt-[80px] mb-[70px]',
              isGeminiHeaderEnabled && 'xl:!top-[100px]  top-10'
            )}
          >
            <div className='bg-off-white pt-4'>
              <GeminiPropertyDetailTabs
                onChange={(index) => {
                  scrollToTargetAdjusted(sectionRefs[index], HEADER_HEIGHT);
                }}
                selected={isAtTop ? 0 : activeSection}
                tabs={tabs}
              />
            </div>
          </div>

          <div className='lg:border-r-[1.5px] border-red-1'>
            <section ref={sectionRefs[0]} className='mt-8 scroll-mt-10'>
              <div className='relative'>
                <div className='max-w-4xl mt-[70px]'>
                  <GeminiPropertyOverviewCard property={property} />
                  <HorizontalSeparator />
                </div>
                <div className='hidden lg:block absolute -right-[1.5px] top-0 border-b-[1.5px] border-l-[1.5px] border-red-1 bg-off-white pl-[70px] pb-[70px]'>
                  <GeminiContactAgentCard
                    price={{
                      amount: property.fullListing?.listPrice,
                      currency: MoneyValueCurrencyEnum.Usd,
                    }}
                    estimatedMonthlyPrice={{
                      amount: estimatedMonthlyPrice,
                      currency: MoneyValueCurrencyEnum.Usd,
                    }}
                    property={property}
                  />
                </div>
              </div>
            </section>

            <div className='max-w-4xl'>
              {doesFeaturesExist && (
                <>
                  <section ref={sectionRefs[1]} className='scroll-mt-10'>
                    <GeminiPropertyFeaturesCard
                      features={property.fullListing.features}
                    />
                  </section>
                  <HorizontalSeparator />
                </>
              )}

              <section ref={sectionRefs[2]} className='w-full scroll-mt-10'>
                <GeminiPropertyOfficeInfoCard property={property} />
              </section>

              <HorizontalSeparator />

              <section ref={sectionRefs[3]} className='w-full scroll-mt-10'>
                <GeminiPropertyMlsInfoCard property={property} />
              </section>

              <HorizontalSeparator />
            </div>

            {!isRental(property.fullListing) && (
              <section
                ref={sectionRefs[4]}
                className='scroll-mt-10 w-full mb-20'
              >
                <GeminiMonthlyCostCard
                  listPrice={property.fullListing.listPrice}
                  setEstimatedMonthlyPrice={setEstimatedMonthlyPrice}
                />
              </section>
            )}
          </div>
        </Container>
      </LayoutRedesigned>
    </div>
  );
};

export const getStaticPaths: GetStaticPaths = async () => {
  return { paths: [], fallback: 'blocking' };
};

export const getStaticProps: GetStaticProps<
  PropertyDetailPageProps,
  PropertyPageParams
> = async (ctx) => {
  const contentfulService = new ContentfulService();
  const bffApiService = new BFFApiService();

  const countriesWithStates = await contentfulService.getCountriesWithStates();
  const footerContent = await contentfulService.getFooterContent();
  const allOffices = await bffApiService.fetchAllOfficialOffices();

  if (!countriesWithStates) {
    throw new Error('Unable to fetch countriesWithStates.');
  }

  let property: ListingDetailResponse | null = null;
  const showUSListings = isUSSiteOnly() || isMultiRegionSite();

  try {
    if (showUSListings) {
      const { data } = await new WandererControllerApi(
        getWandererConfiguration()
      ).getListingDetail(ctx.params.mlsId, ctx.params.mlsNumber);
      property = data;
    } else {
      const { data: canadaData } = await new PropertySearchControllerApi(
        getArtemisConfiguration()
      ).getPropertyDetails(ctx.params.mlsId);
      property = getPropertyDetailsFromArtemisListings(
        canadaData?.realPropertyDetailsList?.[0]
      );
    }
  } catch (e) {
    ErrorService.notifyIgnoreNotFound('Unable to fetch property details', e, {
      mls: { mlsId: ctx.params.mlsId, mlsNumber: ctx.params.mlsNumber },
      urlInfo: { slug: ctx.params.slug },
    });
  }

  if (!property) {
    return { notFound: true, revalidate: 1 };
  }

  return {
    props: { property, countriesWithStates, footerContent, allOffices },
  };
};

export default PropertyDetailPage;

interface IShareContentProps {
  property: ListingDetailResponse;
  estimatedMonthlyPrice: number;
}

const ShareContent: FC<IShareContentProps> = ({
  property,
  estimatedMonthlyPrice,
}) => {
  return (
    <>
      <p className='text-light-blue-1 text-5xl font-telegraf'>
        {displayAmount(
          {
            amount: property?.fullListing?.listPrice,
            currency: MoneyValueCurrencyEnum.Usd,
          },
          { hideCurrency: true, hideZeroCents: true }
        )}
      </p>

      {!isRental(property.fullListing) && (
        <p className='font-inter text-lg text-cobalt text-opacity-70 mt-1.5'>
          Est.{' '}
          {displayAmount(
            {
              amount: estimatedMonthlyPrice,
              currency: MoneyValueCurrencyEnum.Usd,
            },
            { hideCurrency: true }
          )}
          /mo
        </p>
      )}

      <div className='flex mt-5'>
        <GeminiContentOptions
          label='Share'
          icon={
            <FontAwesomeIcon icon={faShareNodes} className='bg-blue-1 p-1' />
          }
          containerClassName='z-50'
          items={getShareOptionItems(undefined, property)}
        />
      </div>
    </>
  );
};
