/* eslint-disable @typescript-eslint/explicit-function-return-type */
import classNames from 'classnames';
import { useState } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form';
import { EnumMap } from '../../types';
import ErrorMessage from '../ErrorMessage';

type InputVariantType = 'default' | 'gemini';

export type ControlledTextInputType =
  | 'text'
  | 'email'
  | 'number'
  | 'hidden'
  | 'date'
  | 'datetime-local'
  | 'image'
  | 'month'
  | 'password'
  | 'search'
  | 'tel'
  | 'time'
  | 'url'
  | 'week';

export type ControlledTextInputModeProps =
  | 'none'
  | 'text'
  | 'tel'
  | 'url'
  | 'email'
  | 'numeric'
  | 'decimal'
  | 'search';

export interface ControlledTextV7InputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName> {
  type?: ControlledTextInputType;
  inputMode?: ControlledTextInputModeProps;
  label?: string;
  subLabel?: string;
  readOnly?: boolean;
  noBorder?: boolean;
  placeholder?: string;
  hideErrorMessage?: boolean;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  variant?: InputVariantType;
}

const ControlledTextInputV7 = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  subLabel,
  type = 'text',
  inputMode = 'text',
  readOnly,
  noBorder,
  placeholder,
  hideErrorMessage = false,
  shouldUnregister = true,
  startAdornment,
  endAdornment,
  variant = 'default',
  ...rest
}: ControlledTextV7InputProps<TFieldValues, TName>) => {
  const [focus, setFocus] = useState<boolean>(false);

  const labelVariantClassMap: EnumMap<InputVariantType, string> = {
    default: 'text-lg font-inter text-gray-400',
    gemini: 'font-telegraf font-normal text-lg text-cobalt',
  };

  const inputContainerVariantsClassMap: EnumMap<InputVariantType, string> = {
    default: classNames('border rounded', {
      'ring-1 ring-dark': focus && !noBorder,
    }),
    gemini: classNames('bg-off-white px-[14px] border-[1.5px] border-cobalt', {
      'ring-1 ring-cobalt ring-offset-2': focus && !noBorder,
    }),
  };

  const inputVariantClassMap: EnumMap<InputVariantType, string> = {
    default: 'p-1.5',
    gemini:
      'font-inter font-normal text-base text-cobalt bg-off-white py-1.5 px-0',
  };

  return (
    <Controller
      shouldUnregister={shouldUnregister}
      {...rest}
      render={({
        field: { onChange, value, name, onBlur },
        fieldState: { error },
      }) => (
        <div className='w-full space-y-1'>
          {label && (
            <label className='inline-block font-inter' htmlFor={name}>
              <span className={labelVariantClassMap[variant]}>{label}</span>
              {!!subLabel && (
                <span className='text-sm text-gray-400 ml-1'>{subLabel}</span>
              )}
            </label>
          )}
          <div
            className={classNames(
              'flex items-stretch overflow-hidden',
              inputContainerVariantsClassMap[variant],
              {
                'border-none': noBorder,
              }
            )}
          >
            {startAdornment && <div>{startAdornment}</div>}
            <input
              id={name}
              value={value}
              onFocus={() => setFocus(true)}
              onBlur={() => {
                setFocus(false);
                onBlur();
              }}
              onChange={onChange}
              type={type}
              inputMode={inputMode}
              name={name}
              readOnly={readOnly}
              placeholder={placeholder}
              className={classNames(
                'appearance-none w-full border-none focus:outline-none focus:ring-0',
                inputVariantClassMap[variant],
                {
                  'bg-gray-50': readOnly,
                }
              )}
            />
            {endAdornment && <div>{endAdornment}</div>}
          </div>
          {error && !hideErrorMessage && (
            <ErrorMessage message={error.message} />
          )}
        </div>
      )}
    />
  );
};

export default ControlledTextInputV7;
