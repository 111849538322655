/* eslint-disable @next/next/no-img-element */
import {
  faArrowLeft,
  faArrowRight,
  faClose,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { IPhoto } from '../../types/real-api-types';

interface CarouselProps {
  propertyImages: IPhoto[];
  onClose(): void;
}

const GeminiCarousel: React.FC<CarouselProps> = ({
  propertyImages,
  onClose,
}) => {
  const [carouselStatus, setCarouselStatus] = useState(1);

  return (
    <div className='flex flex-col items-center justify-start relative md:h-screen'>
      <div className='md:w-[calc(100vw-70px)] md:flex items-center justify-end mb-2 hidden'>
        <button
          onClick={onClose}
          className='h-10 w-10 flex items-center justify-center rounded-full ring-2 ring-new-primary ring-inset shrink-0'
        >
          <FontAwesomeIcon icon={faClose} className='text-off-white text-xl' />
        </button>
      </div>
      <Carousel
        className='md:w-[calc(100vw-70px)] w-[calc(100vw-40px)] md:h-[calc(100vh-173px)] h-[244px]'
        onChange={(index) => setCarouselStatus(index + 1)}
        showStatus={false}
        renderIndicator={() => null}
        renderArrowPrev={(clickHandler, hasPrev) =>
          hasPrev && (
            <button
              className='md:h-10 md:w-10 md:flex items-center justify-center rounded-full md:bg-new-primary absolute z-10 md:top-2/4 bottom-0'
              onClick={clickHandler}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
          )
        }
        renderArrowNext={(clickHandler, hasNext) =>
          hasNext && (
            <button
              className='md:h-10 md:w-10 md:flex items-center justify-center rounded-full md:bg-new-primary absolute right-0 md:top-2/4 bottom-0'
              onClick={clickHandler}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          )
        }
        renderThumbs={() => null}
      >
        {propertyImages.map((item) => (
          <div
            key={item.index}
            className='w-full relative md:h-[calc(100vh-173px)] h-[244px] md:px-20 md:pb-0 pb-[44px]'
          >
            <img
              src={item.large}
              alt='property-image'
              className='h-full w-auto object-fill object-center'
            />
            <span className='md:hidden absolute bottom-0 left-1/2 transform -translate-x-1/2 font-telegraf text-base text-cobalt'>
              {carouselStatus}/{propertyImages.length}
            </span>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default GeminiCarousel;
