import { isEmpty } from 'lodash';
import { FC } from 'react';
import { ListingDetailResponse } from '../../../openapi/wanderer';

interface IGeminiMlsDisclaimerProps {
  property: ListingDetailResponse;
}

const GeminiMlsDisclaimer: FC<IGeminiMlsDisclaimerProps> = ({ property }) => {
  const mlsDisclaimer = (property?.mlsInfo?.lexiconItems || [])?.find(
    (item) => item?.type === 'disclaimer'
  );

  if (isEmpty(mlsDisclaimer)) return null;

  return (
    <p
      className='font-inter text-lg text-cobalt text-opacity-70 mt-10'
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: mlsDisclaimer?.content,
      }}
    />
  );
};

export default GeminiMlsDisclaimer;
